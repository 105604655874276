import { useEffect, useState } from "react"

export function useInAppBrowser() {
  const [inAppBrowserType, setInAppBrowserType] = useState<
    | "instagram"
    | "tiktok"
    | "facebook"
    | "twitter"
    | "snapchat"
    | "whatsapp"
    | null
  >(null)

  useEffect(() => {
    if (window?.navigator == null) {
      return
    }

    if (window.navigator.userAgent.match(/instagram/i)) {
      setInAppBrowserType("instagram")
    } else if (window.navigator.userAgent.match(/musical_ly/i)) {
      setInAppBrowserType("tiktok")
    } else if (window.navigator.userAgent.match(/facebook/i)) {
      setInAppBrowserType("facebook")
    } else if (window.navigator.userAgent.match(/twitter/i)) {
      setInAppBrowserType("twitter")
    } else if (window.navigator.userAgent.match(/snapchat/i)) {
      setInAppBrowserType("snapchat")
    } else if (window.navigator.userAgent.match(/whatsapp/i)) {
      setInAppBrowserType("whatsapp")
    } else {
      setInAppBrowserType(null)
    }
  }, [])

  return { isInAppBrowser: inAppBrowserType !== null, inAppBrowserType }
}
