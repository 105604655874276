import { signIn } from "next-auth/react"
import mobileApp, { useMobileAppHelper } from "lib/mobileApp"
import { useState } from "react"
import {
  Dialog,
  DialogTitle,
  DialogHeader,
  DialogContent,
} from "./shadcn/ui/dialog"
import { Button } from "./shadcn/ui/button"
import useTranslateFunction from "@hooks/useTranslateFunction"
import { useGoogleCalendars } from "@hooks/useCalendars"
import { useInAppBrowser } from "@hooks/useInAppBrowser"
import { toast } from "sonner"

export function MissingGoogleAccountOrPermissionsButton({
  callbackUrl,
}: {
  callbackUrl?: string
}) {
  const { missingGoogleAccount } = useGoogleCalendars()
  const { isMobileApp } = useMobileAppHelper()
  const { t } = useTranslateFunction()
  const { isInAppBrowser } = useInAppBrowser()
  const [isModalOpen, setIsModalOpen] = useState(false)

  function openDialogOrConnectGoogleAccount() {
    if (missingGoogleAccount) {
      connectGoogleAccount()
    } else {
      setIsModalOpen(true)
    }
  }

  async function connectGoogleAccount() {
    if (isMobileApp) {
      mobileApp.connectGoogleCalendar()
    } else if (isInAppBrowser) {
      toast.warning(
        <div className="flex items-start gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="mt-0.5 h-5 w-5 flex-shrink-0"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z" />
            <line x1="12" y1="9" x2="12" y2="13" />
            <line x1="12" y1="17" x2="12.01" y2="17" />
          </svg>
          <div>
            {t(
              "missingGoogleAccount_Para conectar tu cuenta de Google debes hacerlo desde nuestra App, Chrome o Safari."
            )}
            <br />
            <br />
            {t(
              "missingGoogleAccount_Mientras selecciona y prueba el calendario de Confirmafy."
            )}
          </div>
        </div>,
        {
          position: "top-center",
          id: "google-account-warning", // Prevent duplicates
          dismissible: true, // Make toast dismissable
          duration: 10000, // Keep toast until dismissed
        }
      )
    } else {
      await signIn("google", callbackUrl ? { callbackUrl } : {}, {
        scope:
          "openid email profile https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
        access_type: "offline",
        prompt: "consent",
        response_type: "code",
      })
    }
  }

  return (
    <div className="">
      <Button variant="default" onClick={openDialogOrConnectGoogleAccount}>
        {t("onboard_Conectar Cuenta de Google")}
      </Button>
      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {t("onboard_Agregar calendario de Google")}
            </DialogTitle>
          </DialogHeader>

          <MissingGoogleCalendarPermissions
            connectGoogleAccount={connectGoogleAccount}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}

function MissingGoogleCalendarPermissions({
  connectGoogleAccount,
}: {
  connectGoogleAccount: () => void
}) {
  const { t } = useTranslateFunction()

  return (
    <div className="bg-white p-8 sm:rounded-md">
      <h2 className="bold mb-2 mt-4 text-xl">
        {t(
          "missingGoogleCalendarPermissions_Presiona el botón de abajo y haz check en los siguientes permisos:"
        )}
      </h2>

      <img className="mx-auto max-w-full " src="/allow-google-calendar.png" />

      <button
        className="mx-auto mt-4 flex max-w-lg items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 md:px-10 md:py-4 md:text-lg"
        onClick={connectGoogleAccount}
      >
        {t("missingGoogleCalendarPermissions_Dar permisos")}
      </button>
    </div>
  )
}
