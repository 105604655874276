import type { Calendar, User } from "@prisma/client"
import { getTranslatedStringForLocale } from "./i18n"

export const TYPES_OF_MESSAGE = [
  {
    name: "Confirmación de asistencia",
    description: "Preguntar a tu cliente si podrá asistir a tu cita",
    value: "ask_for_confirmation",
  },
  {
    name: "Solo recordatorio",
    description: "Solo recordar a tu cliente que tiene una cita",
    value: "only_remind",
  },
]

export function getConfirmationQuestions(locale: string) {
  return {
    // Keys here map to WhatsApp template names
    flexible_template_vas_a_poder_asistir: getTranslatedStringForLocale(
      {
        pt: "Você pode comparecer?",
        es: "¿Vas a poder asistir?",
        en: "Will you be able to attend?",
        it: "Sei in grado di venire?",
        fr: "Tu pourras venir?",
      },
      locale
    ),
    flexible_template_deseas_confirmar: getTranslatedStringForLocale(
      {
        pt: "Você quer confirmar?",
        es: "¿Deseas confirmar?",
        en: "Would you like to confirm?",
        it: "Vuoi confermare?",
        fr: "Tu veux confirmer?",
      },
      locale
    ),
  }
}

export function getInitialValues({
  existingCalendarFromDBORGoogleCalendar,
  user,
  mostRecentUpdatedCalendar,
  t,
}: {
  existingCalendarFromDBORGoogleCalendar: Calendar | null
  user: User
  mostRecentUpdatedCalendar?: Calendar | null
  t: (s: string, options?: Record<string, string>) => string
}) {
  let calendar = existingCalendarFromDBORGoogleCalendar

  if (mostRecentUpdatedCalendar) {
    calendar = mostRecentUpdatedCalendar
  }

  // Type of message
  const typeOfMessage = calendar?.typeOfMessage || TYPES_OF_MESSAGE[0].value
  const userName = user?.name ?? t("getInitialValues_[Tu nombre]")

  if (user == null) {
    console.error("User is null in getInitialValues")
  }

  // Confirmation message settings
  const confirmationMessageTitle =
    calendar?.confirmationMessageTitle ||
    t("getInitialValues_Recordatorio de cita con [Tu nombre]")
  const confirmationMessageBody =
    calendar?.confirmationMessageBody ||
    t("getInitialValues_Tienes una cita con {{userName}}", {
      userName: user?.name ?? "",
    })
  const confirmationQuestion =
    calendar?.confirmationQuestion ||
    t("getInitialValues_¿Vas a poder asistir?")

  const dateAndTimeFormatForMessage =
    calendar?.dateAndTimeFormatForMessage || "show_date_and_time"

  // Appointment booked message settings
  const sendAppointmentBookedMessage =
    calendar?.sendAppointmentBookedMessage || false
  const appointmentBookedMessageTitle =
    calendar?.appointmentBookedMessageTitle ||
    t("getInitialValues_Cita agendada con {{userName}}", {
      userName: user?.name ?? "",
    })
  const appointmentBookedMessageBody =
    calendar?.appointmentBookedMessageBody ||
    t("getInitialValues_Hola, te hemos agendado. Información de tu cita:")
  const minutesAfterAppointmentCreationToSendAppointmentBookedMessage =
    calendar?.minutesAfterAppointmentCreationToSendAppointmentBookedMessage || 5
  const appointmentBookedIncludeDate =
    calendar?.appointmentBookedIncludeDate || true
  const appointmentBookedIncludeTime =
    calendar?.appointmentBookedIncludeTime || true
  const appointmentBookedIncludeExtraInfo =
    calendar?.appointmentBookedIncludeExtraInfo || true
  const appointmentBookedExtraInfo =
    calendar?.appointmentBookedExtraInfo ||
    t("getInitialValues_Te esperamos ese día.")

  // Just reminder message settings
  const reminderMessageTitle =
    calendar?.reminderMessageTitle ||
    t("getInitialValues_Recordatorio de cita con {{userName}}", {
      userName: user?.name ?? "",
    })
  const reminderMessageBody =
    calendar?.reminderMessageBody ||
    t("getInitialValues_Tienes una cita con {{userName}}", {
      userName: user?.name ?? "",
    })

  // Post appointment message settings
  const sendPostAppointmentMessage =
    calendar?.sendPostAppointmentMessage || false
  const postAppointmentMessageTitle =
    calendar?.postAppointmentMessageTitle ||
    t("getInitialValues_Gracias por venir a tu cita con {{userName}}", {
      userName: user?.name ?? "",
    })
  const postAppointmentMessageBody =
    calendar?.postAppointmentMessageBody ||
    t(
      "getInitialValues_Por venir a la cita de hoy. Si quieres agendar una nueva escríbenos: +{{phoneNumber}}",
      {
        phoneNumber: user.phoneNumber ?? "",
      }
    )
  const hoursAfterAppointmentToSendPostAppointmentMessage =
    calendar?.hoursAfterAppointmentToSendPostAppointmentMessage || 1

  const providerNameForHeading = calendar?.providerNameForHeading || userName

  const phoneNumber = calendar?.phoneNumber
    ? `+${calendar?.phoneNumber}`
    : `+${user.phoneNumber}`
  const onConfirmationReplyMessage =
    calendar?.onConfirmationReplyMessage ||
    t("getInitialValues_Gracias por confirmar tu cita")
  const onCancelationReplyMessage =
    calendar?.onCancelationReplyMessage ||
    t("getInitialValues_Listo, tu cita ha sido cancelada")
  const sendContactCardIfAppointmentIsCanceled =
    calendar?.sendContactCardIfAppointmentIsCanceled === false ? false : true
  const sendLinkToAddEventToCalendar =
    calendar?.sendLinkToAddEventToCalendar || false

  const hoursBeforeToSendNotification =
    calendar?.hoursBeforeToSendNotification || 24

  // Same day reminder settings
  const sendAdditionalReminderDayOfAppointment =
    calendar?.sendAdditionalReminderDayOfAppointment || false
  const hourToSendSameDayReminder = calendar?.hourToSendSameDayReminder || 7
  const minutesBeforeAppointmentToSendSameDayReminder =
    calendar?.minutesBeforeAppointmentToSendSameDayReminder || 180
  const sameDayReminderTitle =
    calendar?.sameDayReminderTitle ||
    t("getInitialValues_Recordatorio de cita con {{userName}}", {
      userName: user?.name ?? "",
    })
  const sameDayReminderBody =
    calendar?.sameDayReminderBody ||
    t("getInitialValues_Tienes una cita con {{userName}}", {
      userName: user?.name ?? "",
    })
  const sameDayReminderIncludeDate =
    calendar?.sameDayReminderIncludeDate || true
  const sameDayReminderIncludeTime =
    calendar?.sameDayReminderIncludeTime || true
  const sameDayReminderIncludeExtraInfo =
    calendar?.sameDayReminderIncludeExtraInfo || false
  const sameDayReminderExtraInfo = calendar?.sameDayReminderExtraInfo || ""

  const sendNextDayConfirmationsAllAtTheSameTime =
    calendar?.sendNextDayConfirmationsAllAtTheSameTime || false
  const hourToSendAllConfirmationsAtTheSameTime =
    calendar?.hourToSendAllConfirmationsAtTheSameTime || 11

  const useMilitaryTimeFormat =
    calendar?.useMilitaryTimeFormat ||
    new Intl.DateTimeFormat(undefined, { hour: "numeric" }).resolvedOptions()
      .hour12 === false

  const guessTimezoneFromPhoneNumber =
    calendar?.guessTimezoneFromPhoneNumber || false

  const useYourOwnNumberEnabled = calendar?.useYourOwnNumberEnabled || false
  const connectedPhoneNumberId = calendar?.connectedPhoneNumberId || null
  const imageUrlForMessageHeader = calendar?.imageUrlForMessageHeader || null

  let timeZone

  if (calendar?.timeZone && calendar?.timeZone !== "UTC") {
    timeZone = calendar?.timeZone
  } else {
    // We try to read the timezone from the device

    timeZone = window.Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  return {
    providerNameForHeading,
    confirmationMessageTitle: confirmationMessageTitle.slice(0, 60),
    confirmationMessageBody,
    confirmationQuestion,
    reminderMessageTitle: reminderMessageTitle.slice(0, 60),
    reminderMessageBody,
    phoneNumber,
    onConfirmationReplyMessage,
    onCancelationReplyMessage,
    sendContactCardIfAppointmentIsCanceled,
    sendLinkToAddEventToCalendar,
    hoursBeforeToSendNotification,
    sendAdditionalReminderDayOfAppointment,
    hourToSendSameDayReminder,
    typeOfMessage,
    useMilitaryTimeFormat,
    sendPostAppointmentMessage,
    postAppointmentMessageTitle,
    postAppointmentMessageBody,
    hoursAfterAppointmentToSendPostAppointmentMessage,
    guessTimezoneFromPhoneNumber,
    timeZone,
    useYourOwnNumberEnabled,
    connectedPhoneNumberId,
    dateAndTimeFormatForMessage,
    hourToSendAllConfirmationsAtTheSameTime,
    sendNextDayConfirmationsAllAtTheSameTime,
    imageUrlForMessageHeader,
    sendAppointmentBookedMessage,
    appointmentBookedMessageTitle,
    appointmentBookedMessageBody,
    minutesAfterAppointmentCreationToSendAppointmentBookedMessage,
    appointmentBookedIncludeDate,
    appointmentBookedIncludeTime,
    appointmentBookedIncludeExtraInfo,
    appointmentBookedExtraInfo,
    minutesBeforeAppointmentToSendSameDayReminder,
    sameDayReminderTitle,
    sameDayReminderBody,
    sameDayReminderIncludeDate,
    sameDayReminderIncludeTime,
    sameDayReminderIncludeExtraInfo,
    sameDayReminderExtraInfo,
  }
}
