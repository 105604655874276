import { trpc } from "lib/trpc"
import { getInitialValues } from "lib/calendarHelpers"
import { useUser } from "./useUser"
import type { Calendar } from "@prisma/client"
import useTranslateFunction from "./useTranslateFunction"

export function useCreateCalendar({
  onSuccess,
}: {
  onSuccess?: (calendar: Calendar) => void
}) {
  const { user } = useUser()
  const trpcUtils = trpc.useContext()
  const mutation = trpc.calendar.upsert.useMutation({
    onError: (err) => {
      console.log(err)
      alert("Hubo un error guardando tu configuración")
    },
    onSuccess: async (data) => {
      await trpcUtils.calendar.getCalendars.refetch()
      onSuccess && onSuccess(data)
    },
  })
  const { t } = useTranslateFunction()

  async function createCalendar({
    phoneNumber,
    confirmationMessageTitle,
    confirmationMessageBody,
    confirmationQuestion,
    summary,
    typeOfMessage,
    dateAndTimeFormatForMessage,
    id,
    imageUrlForMessageHeader,
  }: {
    phoneNumber?: string
    confirmationMessageTitle?: string
    confirmationMessageBody?: string
    confirmationQuestion?: string
    summary?: string
    typeOfMessage?: string
    dateAndTimeFormatForMessage?: string
    id?: string
    imageUrlForMessageHeader?: string
  }) {
    if (mutation.isLoading) {
      return
    }

    const messageValue =
      confirmationMessageTitle && confirmationMessageBody
        ? {
            confirmationMessageTitle,
            confirmationMessageBody,
            reminderMessageTitle: confirmationMessageTitle,
            reminderMessageBody: confirmationMessageBody,
          }
        : {}

    const additionalValues = typeOfMessage
      ? {
          typeOfMessage,
        }
      : {}

    const confirmationQuestionValue = confirmationQuestion
      ? {
          confirmationQuestion,
        }
      : {}

    await mutation.mutate({
      ...getInitialValues({
        existingCalendarFromDBORGoogleCalendar: null,
        user: user!,
        mostRecentUpdatedCalendar: null,
        t: t,
      }),

      id,
      summary: summary ? summary : t("useCreateCalendar_Calendario Confirmafy"),
      providerNameAndDetailsForBody: "",
      source: "native",
      phoneNumber: phoneNumber || user?.phoneNumber!,
      dateAndTimeFormatForMessage: dateAndTimeFormatForMessage,
      imageUrlForMessageHeader: imageUrlForMessageHeader,

      ...messageValue,
      ...additionalValues,
      ...confirmationQuestionValue,
    })
  }

  async function createConfirmafyCalendarFromCopy({
    summary,
    calendarFromWhichToCopy,
  }: {
    summary?: string
    calendarFromWhichToCopy: Calendar
  }) {
    const initialValues = getInitialValues({
      existingCalendarFromDBORGoogleCalendar: null,
      user: user!,
      mostRecentUpdatedCalendar: calendarFromWhichToCopy,
      t: t,
    })

    await mutation.mutate({
      ...initialValues,
      summary: summary ? summary : t("useCreateCalendar_Calendario Confirmafy"),
      providerNameAndDetailsForBody: "",
      source: "native",
      phoneNumber: initialValues.phoneNumber.replace("+", ""),
    })
  }

  return { createCalendar, createConfirmafyCalendarFromCopy }
}
